<template>
	<div>
		<h2 class="primary--text text-h5 mb-2">العلامات</h2>
		<v-row
			dense
			align="center">
			<v-col
				cols="12"
				sm="5">
				<v-autocomplete
					v-model="selectedStudentId"
					:items="allStudents"
					:loading="loading"
					item-text="Name"
					item-value="Id"
					label="الطالب"
					outlined
					dense
					hide-details
					clearable
					:menu-props="{ offsetY: true }"
					class="rounded-lg"></v-autocomplete>
			</v-col>
			<v-col
				cols="12"
				sm="5">
				<v-btn
					color="primary"
					block
					outlined
					@click="dateDialog = true">
					<template v-if="dates && dates[0] && dates[1]">
						<strong class="text-caption">{{ dates[0].replaceAll('-', '/') }}</strong>
						-
						<strong class="text-caption">{{ dates[1].replaceAll('-', '/') }}</strong>
					</template>
					<template v-else>
						<v-icon left> mdi-calendar-month-outline </v-icon>
						تاريخ
					</template>
				</v-btn>
			</v-col>
			<v-col
				cols="12"
				sm="2">
				<v-btn
					:disabled="!selectedStudentId"
					color="primary"
					block
					@click="fetchMarks">
					بحث
				</v-btn>
			</v-col>
		</v-row>
		<div>
			<v-divider class="my-2"></v-divider>
			<!-- student test -->
			<v-card class="rounded-lg elevation-1 overflow-hidden mt-2">
				<v-data-table
					:headers="testsHeader"
					:items="marks ? marks.Marks : []"
					:loading="loadingMarks"
					dense
					:items-per-page="-1"
					hide-default-footer
					height="calc(50vh)"
					fixed-header
					mobile-breakpoint="0">
					<!-- mark template -->
					<template v-slot:item.mark="{ item }">
						<div
							class="font-weight-bold"
							style="font-size: inherit; width: max-content">
							<template v-if="item.IsAbsent">لم يقدم</template>
							<template v-else>
								<span
									:class="item.Mark >= item.Test.MinGrade ? 'myGreen--text' : 'red--text'">
									{{ item.Mark }}
								</span>
								\{{ item.TestMaxMark }}
							</template>
						</div>
					</template>
					<!-- mark template -->
					<template v-slot:item.Test.Date="{ item }">
						{{ moment(item.Test.Date).format('YYYY/MM/DD') }}
					</template>
				</v-data-table>
			</v-card>

			<!-- students statics -->
			<v-card
				class="rounded-lg elevation-1 overflow-hidden mt-2 pa-4"
				v-if="marks">
				<div class="mb-2 text-center font-weight-bold">
					<v-btn
						:to="{
							name: 'course',
							params: {
								id: marks.CourseId,
							},
						}"
						exact
						icon
						text
						title="الشعبة"
						class="actions-btn"
						depressed>
						<v-icon class="myOrange--text"> mdi-bookshelf </v-icon>
					</v-btn>
					الصف: {{ marks.SpecialtyName }} - شعبة:
					{{ marks.CourseName }}
				</div>
				<div class="d-flex justify-space-around align-end">
					<div>
						المعدل
						<span
							v-if="!marks.Average || marks.Average === '-'"
							class="text-h5 font-weight-bold primary--text">
							-
						</span>
						<template v-else-if="marks.Average.split('/').length > 1">
							<span class="text-h5 font-weight-bold primary--text">
								{{ marks.Average.split('/')[0] }}
							</span>
							<span class="text-caption font-weight-bold">
								\
								{{ marks.Average.split('/')[1] }}
							</span>
						</template>
						<template v-else>
							<span class="text-h5 font-weight-bold primary--text">
								{{ marks.Average }}
							</span>
						</template>
					</div>
					<div>
						الترتيب
						<span class="text-h5 font-weight-bold primary--text">{{ marks.Rank }}</span>
					</div>
					<div>
						عدد العلامات التامة
						<span class="text-h5 font-weight-bold primary--text">{{
							marks.CountOfTotalMark
						}}</span>
					</div>
				</div>
			</v-card>
		</div>
		<v-dialog
			v-model="dateDialog"
			max-width="300px"
			transition="dialog-transition">
			<v-card class="pa-2">
				<v-date-picker
					locale="ar-SY"
					v-model="dates"
					range></v-date-picker>
				<v-row dense>
					<v-col cols="6">
						<v-btn
							block
							color="primary"
							@click="
								dates = null;
								dateDialog = false;
							">
							إعادة التعين
						</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn
							block
							outlined
							color="primary"
							@click="dateDialog = false">
							إلغاء
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'MarksDialog',

	data() {
		return {
			loading: true,
			loadingMarks: false,
			searched: false,

			selectedStudentId: null,

			allStudents: null,
			marks: null,
			dates: null,

			dateDialog: false,
		};
	},

	watch: {
		dates() {
			if (this.dates && this.dates[1]) this.dateDialog = false;
		},
	},

	computed: {
		testsHeader() {
			const testsHeader = [
				{
					text: 'المادة',
					value: 'Test.SubjectName',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'محتوى الاختبار',
					value: 'Test.Details',
					sortable: false,
					showInMobile: true,
				},
				{
					text: 'نوع الاختبار',
					value: 'Test.TestType',
					sortable: false,
				},
				{
					text: 'العلامة',
					value: 'mark',
					sortable: false,
					showInMobile: true,
				},
				{ text: 'تاريخ', value: 'Test.Date', showInMobile: true },
			];
			if (this.$vuetify.breakpoint.mobile) {
				return testsHeader.filter((e) => e.showInMobile);
			}
			return testsHeader;
		},

		sortedDate() {
			return this.dates
				? new Date(this.dates[0]).getTime() < new Date(this.dates[1]).getTime()
					? [new Date(this.dates[0]).getTime(), new Date(this.dates[1]).getTime()]
					: [new Date(this.dates[1]).getTime(), new Date(this.dates[0]).getTime()]
				: [,];
		},
	},

	methods: {
		fetchMarks() {
			this.searched = false;
			this.loadingMarks = true;
			this.$store
				.dispatch('sideBar/fetchMarks', {
					studentId: this.selectedStudentId,
					startDate: this.sortedDate[0],
					endDate: this.sortedDate[1],
				})
				.then((res) => {
					this.marks = res;
				})
				.finally(() => {
					this.searched = true;
					this.loadingMarks = false;
				});
		},
		fetchAllStudents() {
			this.loading = true;
			this.$store
				.dispatch('sideBar/fetchAllStudents')
				.then((res) => {
					this.allStudents = res;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		moment,
	},

	created() {
		this.fetchAllStudents();
	},
};
</script>
